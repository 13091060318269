.wrapper {
  display: grid;
  gap: 8px;
  position: relative;

  &.errored {
    input,
    textarea {
      border: 1px solid var(--error-500, #d87e7e);
      background: var(--error-100, #f7e5e5);
    }
  }

  .error {
    color: var(--error-600, #b46767);
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.24px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }

  .label {
    padding-left: 4px;
    color: var(--neutral-800, #404346);
  }

  input,
  textarea {
    border-radius: 12px;
    border: 1px solid var(--light-blue-500, #0376be);
    background-color: var(--light-blue-100, #f8fcff);
    transition: background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      border 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    padding: 12px 16px;

    &:hover {
      border: 1px solid var(--light-blue-500, #0376be);
      background-color: var(--light-blue-200, #f8fcff);
    }
  }
}
