.wrapper {
  border-radius: 32px;
  background: --neutral-100;
  box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);
  padding: 42px 32px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 32px;
  align-items: center;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
    padding: 42px 12px;
  }
  
  @media (max-width: 350px) {
    margin: 0 -16px;
    border-radius: 0;
  }

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    text-align: center;

    @media (max-width: 720px) {
      max-width: 300px;
      margin: 0 auto;
      gap: 12px;
    }

    span {
      color: var(--navy-500);

      @media (max-width: 720px) {
        font-size: 32px;
        margin-top: 4px;
      }
    }

    p {
      margin-top: 12px;
      color: var(--navy-500);

      font-size: clamp(20px, calc(20vw/7.68), 32px);

      @media (max-width: 720px) {
        font-size: 24px;
        margin-top: 0;
      }
    }
  }
}