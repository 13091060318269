.wrapper {
  position: relative;
  display: grid;
  gap: 8px;
  grid-template-columns: 32px 1fr;
  cursor: pointer;

  a {
    text-decoration: underline;
    border-radius: 4px;
    font-weight: 500;
  }

  &.errored {
    .checkMark {
      border: 1px solid var(--error-600, #b46767);
    }
  }

  .error {
    color: var(--error-600, #b46767);
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0.24px;
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
  }

  .input {
    opacity: 0;
    position: absolute;
    width: 0px;
    height: 0px;
    pointer-events: none;
  }

  .checkMark {
    border-radius: 12px;
    border: 1px solid var(--navy-600, #1f2e60);
    width: 32px;
    height: 32px;
    display: block;
    position: relative;
    transition: border 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    }
  }

  input:focus-visible ~ .checkMark {
    outline: 3px dashed var(--navy-600, currentColor);
    outline-offset: 4px;
    border-radius: 4px;
  }

  input:checked ~ .checkMark {
    border: 1px solid var(--light-blue-500, #0376be);
    background: var(--light-blue-500, #0376be);

    svg {
      opacity: 1;
    }
  }

  &:hover {
    .checkMark {
      border: 1px solid var(--light-blue-500, #0376be);
      background-color: var(--neutral-200, #efefef);
    }
  }
}
