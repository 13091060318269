.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 18px;

  min-width: clamp(163px, calc(163vw / 7.68), 278px);
  min-height: 184px;
  height: 100%;
  padding: 16px;

  &.primary {
    border-radius: 32px;
    border: 2px solid var(--light-blue-500, #0376be);
    background-color: var(--light-blue-500, #0376be);
    box-shadow: 2px 2px 4px 0px rgba(39, 58, 120, 0.15);
    transition: border 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      box-shadow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    span {
      color: var(--neutral-100, #fff);
    }

    svg {
      path {
        fill: var(--neutral-100, #fff);
      }
    }

    &:hover {
      border: 2px solid var(--light-blue-300, #5faad8);
      background-color: var(--light-blue-300, #5faad8);
    }

    &:active {
      border: 2px solid var(--light-blue-500, #0376be);
      background: var(--light-blue-600, #025e98);
    }
  }

  &.secondary {
    border-radius: 24px;
    border: 2px solid var(--neutral-900, #202123);
    background: var(--neutral-900, #202123);
    box-shadow: 2px 2px 4px 0px rgba(39, 58, 120, 0.15);
    transition: border 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      box-shadow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    span {
      color: var(--yellow-500, #f9bc41);
    }

    svg {
      path {
        fill: var(--yellow-500, #f9bc41);
      }
    }

    &:hover {
      background-color: var(--neutral-800, #404346);
      border: 2px solid var(--neutral-900, #404346);
    }

    &:active {
      background-color: var(--neutral-800, #404346);
      border: 2px solid var(--neutral-900, #404346);
    }
  }

  &.tertiary {
    border-radius: 32px;
    border: 2px solid var(--yellow-400, #fac967);
    background: var(--yellow-400, #fac967);
    box-shadow: 2px 2px 4px 0px rgba(39, 58, 120, 0.15);
    transition: border 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86),
      box-shadow 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86), background-color 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);

    span {
      color: var(--neutral-900, #202123);
    }

    svg {
      path {
        fill: var(--neutral-900, #202123);
      }
    }

    &:hover {
      background-color: var(--yellow-300, #fbd78d);
    }

    &:active {
      background-color: var(--yellow-600, #cb9934);
    }
  }

  @media (max-width: 540px) {
    min-height: 156px;
  }

  svg {
    margin: 0 auto;
  }

  span {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.3px;

    max-width: 185px;
  }
}
