.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  @media (max-width: 864px) {
    grid-template-columns: 1fr;
    max-width: 610px;
    margin: 0 auto;
  }

  .left {
    border-radius: 32px;
    border: 1px solid var(--light-blue-600, #025e98);
    background: var(--light-blue-100, #f8fcff);
    box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);
    padding: 32px clamp(12px, calc(32vw / 7.68), 32px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: clamp(48px, calc(96vw / 7.68), 96px);

    img {
      margin-right: auto;
    }

    p {
      margin-bottom: 32px;
    }
  }

  .post {
    position: relative;
    border-radius: 32px;
    border: 1px solid var(--light-blue-500, #0376be);
    background: var(--light-blue-100, #f8fcff);
    padding: clamp(16px, calc(32vw / 7.68), 32px);
    box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);

    > a {
      display: block;
      position: absolute;
      inset: 0;
      border-radius: 32px;
      z-index: 1;
    }

    img {
      border-radius: 16px;
      width: 100%;
      max-width: 100%;
      height: fit-content;
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  .right {
    border-radius: 32px;
    background: var(--neutral-100, #fff);
    box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);
    padding: 32px clamp(12px, calc(32vw / 7.68), 32px);
    position: relative;

    h2 {
      margin-bottom: 24px;
    }

    > div {
      p + p {
        margin-top: 12px;
      }
    }
  }
}

.form {
  max-width: 400px;
  margin-top: 32px;
  display: grid;
  gap: 24px;

  button {
    margin-top: 16px;
  }
}

.success {
  position: absolute;
  inset: -1px;
  z-index: 10;
  border-radius: 32px;
  background: var(--green-100, #f8fffd);
  box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);

  padding: 36px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  h2 {
    max-width: 484px;
  }
}

.failed {
  position: absolute;
  inset: -1px;
  z-index: 10;
  border-radius: 32px;
  background: var(--error-100, #f7e5e5);
  box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);

  padding: 36px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 24px;

  h2 {
    max-width: 484px;
  }
}
