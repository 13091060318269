.wrapper {
  position: relative;
  overflow: hidden;

  .background {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1826px;
    height: 1841px;

    > svg {
      position: absolute;
    }
    @media (max-width: 1239px) {
      image {
        x: 50%;
      }
    }

    @media (max-width: 1080px) {
      position: relative;
      height: 650px;
      width: 100%;

      image {
        x: 30%;
        y: 63%;
      }

      > svg {
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    @media (max-width: 480px) {
      height: 540px;
      image {
        width: 500px;
        x: 36.5%;
        y: 65%;
      }
    }
  }

  .wrap {
    min-height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;

    @media (max-width: 1080px) {
      min-height: 0;
      margin-top: 240px;

      .content {
        margin: 0 auto;
      }
    }

    .content {
      max-width: 686px;
      position: relative;
      z-index: 1;

      .title {
        margin-bottom: 32px;

        > * {
          color: var(--navy-600, #1f2e60);
        }
      }

      .text {
        max-width: 500px;
      }
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 16px 32px;
      margin-top: 32px;
    }

    .list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-top: 24px;

      @media (max-width: 1239px) {
        max-width: 540px;
      }

      @media (max-width: 480px) {
        grid-template-columns: 1fr;
        gap: 16px;
      }

      li {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }
}
