.wrap {
  overflow: hidden;
  padding: 500px 0;
  margin: -500px 0;
}

.background {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;

  > svg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    transform: translate(50%, 20%);
  }
}

.wrapper {
  h2 {
    text-align: center;
    margin-bottom: 48px;

    @media (max-width: 420px) {
      text-align: left;
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  margin-bottom: 32px;

  @media (max-width: 1140px) {
    grid-template-columns: 1fr;
  }

  .item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 32px;
    background: var(--neutral-100, #fff);
    box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);

    h3 {
      margin-bottom: 24px;
      margin-right: 20px;
    }

    iframe {
      border-radius: 32px;
      aspect-ratio: 587/388;
      width: 100% !important;
      height: auto !important;
      max-height: 400px;
    }

    .content {
      padding: 32px clamp(12px, calc(36vw / 7.68), 36px) clamp(20px, calc(24vw / 7.68), 24px)
        clamp(12px, calc(36vw / 7.68), 36px);

      > p {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 12px;

        @media (max-width: 550px) {
          margin-top: 20px;
          display: grid;
          grid-template-columns: auto 1fr;
          grid-template-areas:
            'icon text'
            'copy copy';

          svg {
            grid-area: icon;
          }

          span {
            grid-area: text;
          }

          button {
            grid-area: copy;
            width: fit-content;
          }
        }

        button {
          margin-left: 4px;
          padding: 6px 18px;
          border-radius: 12px;
          border: 1px solid var(--light-blue-600, #025e98);
          background-color: transparent;
          cursor: pointer;

          color: var(--light-blue-600, #025e98);
          font-size: 14px;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: 0.28px;
          transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86),
            color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86), border 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86);

          &:hover {
            background-color: var(--neutral-200, #efefef);
          }

          &:active {
            color: var(--light-blue-800, #012f4c);
            border: 1px solid var(--light-blue-800, #012f4c);
          }
        }
      }
    }
  }
}

.cta {
  padding: 32px clamp(20px, calc(20vw / 7.68), 40px);
  border-radius: 32px;
  background: var(--yellow-500, #f9bc41);
  box-shadow: 0px 2px 8px 0px rgba(92, 145, 178, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;

  @media (max-width: 540px) {
    display: grid;
    gap: 32px;
  }

  > .text {
    max-width: 600px;

    strong {
      font-weight: 700;
      color: unset !important;
    }

    @media (max-width: 540px) {
      font-size: 16px;
      padding-right: 10px;
    }

    @media (max-width: 320px) {
      padding-right: 0;
    }
  }
}
